import { Chart } from "./../static/chart/chart";
const POST = 'POST';
const PUT = 'PUT';
const GET = 'GET';
const DELETE = 'DELETE';

const WHOAMI_URL = "/_a/user-data";
const BE_URL = process.env.BE_URL || "http://localhost:5656";
const FE_URL = process.env.FE_URL || "http://localhost:3000";
export const ACCESS_TOKEN_KEY = "access_token";
export const DEMO_WORKSPACE_ID = 1;
export const DASHBOARD_URL = "/home";
export const LOGIN_URL = "/login";
export const USER_URL = "/_a/user";
export const WORKSPACE_URL = "/_a/workspace";
export const WORKSPACES_URL = "/_a/workspaces";
export const DASHBOARDS_URL = "/_a/dashboards";
export const DEFAULT_ERROR_MESSAGE = "An error has occurred please try again later";

export const HEADER_COMPONENT_URL = "/header.html";
export const ROLES = Object.freeze([
    "Owner",
    "Admin",
    "Editor",
    "Viewer"
]);
export const SCOPES = Object.freeze([
    "All",
    "Workspace",
    "Me"
]);
export const TASK_STATES: any = {
    0: { description: "Pending", className: "pending", color: '#fcd53f' }, // Unclaimed
    1: { description: "Pending", className: "pending", color: '#fcd53f' }, // WaitingForDependencies
    2: { description: "In progress", className: "pending", color: '#fcd53f' },
    3: { description: "Failure", className: "error", color: '#f82f2f' },
    4: { description: "Time out", className: "error", color: '#f82f2f' },
    5: { description: "Dependency failed", className: "error", color: '#f82f2f' },
    6: { description: "Success", className: "success", color: '#02d26a' },
    7: { description: "Cancelled", className: "initial", color: '#808080' }
};
const OWNER = 0;
const ADMIN = 1;
const TENANT_ADMIN = 0;
const TOAST_TYPE = Object.freeze({
    INFO: "info", 
    SUCCESS: "success", 
    DANGER: "danger", 
    WARNING: "warning "
})

export const DEFAULT_ROLE = "Viewer"

const addServerAddressToUrl = (url: string | URL) => {
    return `${BE_URL}${url}`;
}

export class ResponseClass {
    response?: any;
    status?: number;
    constructor(_response?: any, _status?: number) {
        this.response = _response;
        this.status = _status;
    }
}

export class WhoamiClass extends ResponseClass {
    Authorization?: string | undefined;
    isLoggedIn?: boolean;
    user?: any;
    workspace_id?: any;
}

export class ChartClass { 
    id?: string;
    data?: any;
    chart_label?: string; 
    axis_labels?: any; 
    is_pinned?: any;
    query_id?: any; 
    view_id?: any;
}

export async function isUserLoggedIn(allowRedirectToLogin:boolean = true) {
    const result: WhoamiClass = {
        isLoggedIn: false,
        user: null
    };
    const headers = getAuthHeaders();
    if (Object.keys(headers).length !== 0) {
        try {
            let response = await httpGetRequestWithHeaders(WHOAMI_URL, headers, {}) as WhoamiClass;
            if (response.status === 200) {
                let data = JSON.parse(response.response);
                if (data.success) {
                    let payload =  await checkActiveWorkspaceId(data.payload);
                    result.isLoggedIn = true;
                    result.user = payload;
                }
            }
        } catch (err) {
            console.log(err);
        }
    }
    if (allowRedirectToLogin && !result?.isLoggedIn) redirectToLogin();
    return result;
}

export function activeNavLink(){
    const urlObj = new URL(window.location.href);
    const searchParams = urlObj.searchParams;
    searchParams.delete('w');
    const ref = urlObj.pathname.slice(1) + urlObj.search;
    const navItem = document.querySelector(`.sidenav-link[href~="${ref}"], .sidenav-link a[href~="${ref}"]`);
    navItem?.classList?.add('active');
    const parent = navItem?.closest('.sidenav-item-dropdown');
    parent?.querySelector('.sidenav-link')?.classList?.add('active');
}

export async function checkActiveWorkspaceId(payload:any) {
    return new Promise((resolve, reject) => {
        let searchParams =  new URLSearchParams(window.location.search);
        let w = searchParams.get('w');
        if(w == null || w == '') {
            searchParams.set('w', payload?.workspace_id);
            window.history.replaceState(null, '', `?${searchParams.toString()}`);
            resolve(payload);
        } else if (payload?.workspace_id != w){   
            httpPutRequest('/_a/active-workspace', {id: parseInt(w)}, res => {  
                let data = JSON.parse(res.response);
                if (data.success) {
                    resolve(data.payload);
                } else {
                    reject(payload);
                }
            });
        }else {
            resolve(payload);
        } 
    });
}

export const getAuthHeaders = () => {
    const token = window.localStorage.getItem(ACCESS_TOKEN_KEY);
    return {
        Authorization: token === null? undefined : `Bearer ${token}`
    } as WhoamiClass
}

export const saveTokenToLocalStorage = (token: string) => {
    window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
}

export const removeTokenFromLocalStorage = () => {
    window.localStorage.removeItem(ACCESS_TOKEN_KEY);
}

export const existTokenFromLocalStorage = () => {
    return window.localStorage. getItem(ACCESS_TOKEN_KEY) !== null;
}

export const redirectToLogin = () => {
    window.location.href = LOGIN_URL;
}

export async function load(url: RequestInfo, element: { innerHTML: string; }) {
    const resp = await fetch(url);
    const header = await resp.text();
    element.innerHTML = header;
}

export function httpGetRequest(url: string | URL, params: any,  callback: (response: ResponseClass) => void) {
    url = getUrlWithParams(url, params);
    const http = new XMLHttpRequest();
    http.open(GET, url, true);
    http.send(null);
    http.onreadystatechange = function() {
        if (http.readyState === http.DONE) {
            callback(new ResponseClass(http.responseText, http.status));
        }
    };
}

export function httpGetRequestWithAuthHeaders(url: string | URL, params: any,  callback: (response: ResponseClass) => void) {
    url = getUrlWithParams(url, params);
    let headers = getAuthHeaders();
    const http = new XMLHttpRequest();
    http.open(GET, url, true);
    for (let key in headers) {
        http.setRequestHeader(key, headers[(key as keyof WhoamiClass)]);
    }
    http.withCredentials = true;
    http.send(null);
    http.onreadystatechange = function() {
        if (http.readyState === http.DONE) {
            callback(new ResponseClass(http.responseText, http.status));
        }
    };
}

export async function asyncHttpGetRequest(url: string | URL, params: any) {
    url = getUrlWithParams(url, params);
    return new Promise(function(resolve, reject) {
        const http = new XMLHttpRequest();
        http.open(GET, url, true);
        http.onload = function() {
            if (this.status >= 200 && this.status < 300) {
                resolve({ response: http.responseText, status: http.status });
            } else {
                reject({ response: http.statusText, status: http.status });
            }
        }
        http.send(null);
    })
}

export async function httpGetRequestWithHeaders(url: string | URL, headers: WhoamiClass, params: { query?: any; }) {
    url = getUrlWithParams(url, params);
    return new Promise(function(resolve, reject) {
        const http = new XMLHttpRequest();
        http.open(GET, url, true);
        for (let key in headers) {
            http.setRequestHeader(key, headers[(key as keyof WhoamiClass)]);
        }
        http.withCredentials = true;
        http.onload = function() {
            if (this.status >= 200 && this.status < 300) {
                resolve({ response: http.responseText, status: http.status });
            } else {
                reject({ response: http.statusText, status: http.status });
            }
        }
        http.send(null);
    })
}

export const httpPostRequest = (url: string | URL, body: any, callback: (response: ResponseClass) => void) => {
    url = addServerAddressToUrl(url);
    const headers = getAuthHeaders();
    const http = new XMLHttpRequest();
    http.open(POST, url, true);
    for (let key in headers) {
        http.setRequestHeader(key, headers[(key as keyof WhoamiClass)]);
    }
    if (FormData.prototype.isPrototypeOf(body)) {
        http.onload = function() {
            callback(new ResponseClass(http.responseText, http.status));
        };
        http.send(body);
    } else {
        http.send(JSON.stringify(body));
        http.onreadystatechange = function() {
            if (http.readyState === http.DONE) {
                callback(new ResponseClass(http.responseText, http.status));
            }
        };
    }
}

export const httpPutRequest = (url: string | URL, body:any, callback: (response: ResponseClass) => void) => {
    url = addServerAddressToUrl(url);
    const headers = getAuthHeaders();
    const http = new XMLHttpRequest();
    http.open(PUT, url, true);
    for (let key in headers) {
        http.setRequestHeader(key, headers[(key as keyof WhoamiClass)]);
    }
    if (FormData.prototype.isPrototypeOf(body)) {
        http.onload = function() {
            callback(new ResponseClass(http.responseText));
        };
        http.send(body);
    } else {
        http.send(JSON.stringify(body));
        http.onreadystatechange = function() {
            if (http.readyState === http.DONE) {
                callback(new ResponseClass(http.responseText));
            }
        };
    }   
}

export const httpDeleteRequestWithBody = (url: string | URL, body: any, callback: (response: ResponseClass) => void) => {
    url = addServerAddressToUrl(url);
    const headers = getAuthHeaders();
    const http = new XMLHttpRequest();
    http.open(DELETE, url, true);
    for (let key in headers) {
        http.setRequestHeader(key, headers[(key as keyof WhoamiClass)]);
    }
    http.send(JSON.stringify(body));
    http.onreadystatechange = function() {
        if (http.readyState === http.DONE) {
            callback(new ResponseClass(http.responseText));
        }
    };
}

export const httpDeleteRequest = (url: string | URL, callback: (response: ResponseClass) => void) => {
    httpDeleteRequestWithBody(url, null, callback);
}

const getUrlWithParams = (url: string | URL, params: any) => {
    const searchParams = new URLSearchParams(params);
    if (searchParams.toString() !== "") {
        url = url + '?' + searchParams;
    }
    return addServerAddressToUrl(url);
}

export const getSentenceNode = (data: { sentence: string; }) => {
    const para = document.createElement("p");
    para.innerHTML = data.sentence;
    return para;
}

export const getTableNode = (tableData: { columns: any[]; data: any[]; }) => {
    const tdiv = document.createElement("div");
    const table = document.createElement("table");
    const thead = document.createElement("thead");
    const tbody = document.createElement("tbody");
    // creating empty table
    table.classList.add("table", "table-sm", "table-hover", "table-bordered")
    table.append(thead, tbody);
    // creating head row
    const hrow = document.createElement("tr");
    tableData.columns.forEach((col: string) => {
        const hcell = document.createElement("td");
        hcell.innerHTML = col;
        hrow.appendChild(hcell)
    })
    thead.appendChild(hrow);
    // creating table body
    tableData.data.forEach((rowData: any[]) => {
        const trow = document.createElement("tr");
        rowData.forEach((val: string) => {
            const tcell = document.createElement("td");
            tcell.innerHTML = val;
            trow.appendChild(tcell);
        });
        tbody.appendChild(trow)
    });
    tdiv.classList.add("col-6");
    tdiv.appendChild(table);
    return tdiv;
}

export const getChartNode = (chartData: ChartClass, isBody: boolean) => {
    const chartOptions = getBarChartOptions(chartData.data, chartData.chart_label, chartData.axis_labels);
    // creating a canvas for chart
    const chartCtx = document.createElement("canvas");
    // adding chart to canvas
    new Chart(chartCtx, chartOptions)

    // create card for canvas
    const card = document.createElement("div")
    card.classList.add("card", "mt-2");
    card.appendChild(chartCtx); // adding chart to card

    // Card body
    const cardBody = document.createElement("div");
    cardBody.classList.add("card-body", "card-content")

    // Card Title
    const cardTitle = document.createElement("h6");
    cardTitle.classList.add("card-title", "fw-light");
    cardTitle.innerHTML = chartData.chart_label || "";

    // Card Actions
    const action = document.createElement("span");
    action.classList.add("badge", "cursor-pointer", "default-icon");
    action.innerHTML = `<i class="fas fa-plus fa-lg"></i>`;

    cardBody.appendChild(cardTitle);
    cardBody.appendChild(action);
    if (isBody) {
        card.appendChild(cardBody);
    }
    return card;
}

const chartBackgroundColors = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)',
    'rgba(40, 82, 122, 0.2)',
    'rgba(255, 204, 41, 0.2)'
];
const chartBorderColors = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(40, 82, 122, 1)',
    'rgba(255, 204, 41, 1)'
];

export const getBarChartOptions = (data: any, chartLabel: any, axisLabels: any) => {
    return {
        type: 'bar',
        data: {
            labels: axisLabels,
            datasets: [{
                label: chartLabel,
                data: data,
                backgroundColor: chartBackgroundColors,
                borderColor: chartBorderColors,
                borderWidth: 1,
                barPercentage: 0.4
            }]
        },
        options: {
            plugins: {
                tooltip: {
                    // to add custom tooltip
                }
            },
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        }
    }
}

export const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export const validatePassword = (password: string | any[]) => {
    // TODO: Uncomment alpha numeric with special character password logic

    // const regex = /^[a-zA-Z0-9@\\\\#$%&*()_+\\]\\[';:?.,!^-]{8,}$/
    // return regex.test(password);

    // temporary for testing purposes
    return password.length > 5;
}

export async function getWorkspaceById(workspaceId: number) {
    const headers = getAuthHeaders();
    const url = `${WORKSPACE_URL}/${workspaceId}`;
    const res: any = await httpGetRequestWithHeaders(url, headers, {});
    if (res.status === 200 && res.response !== "") {
        const response = res.response;
        let data = JSON.parse(response);
        if (data.success && data.payload) {
            return data.payload;
        }
    }
    return null;
}

export async function getDashboardById(id:any) {
    const headers = getAuthHeaders();
    const res: any = await httpGetRequestWithHeaders(`/_a/dashboard/${id}`, headers, {});
    if (res.status === 200 && res.response !== "") {
        const response = res.response;
        let data = JSON.parse(response);
        if (data.success && data.payload) return data.payload;
    }
    return {};
}

export function getAllDashboards(user:any) {
    let allDashboards:any[] = user?.member_details?.sidebar?.dashboards || [];
    allDashboards.push({id: user?.member_details?.home_dashboard_id, name:"Home"});
    return allDashboards;
}

export function timeDifference(current: any, previous: any) {
    if (current > previous)
        return timeDifferenceWithPrefix(current, previous, "", " ago");
    else
        return timeDifferenceWithPrefix(previous, current, "In ", "");
}

export function futureTimeDifference(current: any, previous: any) {
    if (current > previous)
        return 'Starting soon';
    else
        return timeDifferenceWithPrefix(previous, current, "In ", "");
}

function timeDifferenceWithPrefix(current: any, previous: any, prefix:any, sufix:any) {
    let msPerMinute = 60 * 1000;
    let msPerHour = msPerMinute * 60;
    let msPerDay = msPerHour * 24;
    let msPerMonth = msPerDay * 30;
    let msPerYear = msPerDay * 365;
    let elapsed = current - previous;

    if (elapsed < msPerMinute) return `${prefix}${timeWithSingularOrPluralNoun(Math.round(elapsed/1000), 'second')}${sufix}`;
    else if (elapsed < msPerHour) return `${prefix}${timeWithSingularOrPluralNoun(Math.round(elapsed/msPerMinute), 'minute')}${sufix}`;
    else if (elapsed < msPerDay ) return `${prefix}${timeWithSingularOrPluralNoun(Math.round(elapsed/msPerHour ), 'hour')}${sufix}`;
    else if (elapsed < msPerMonth) return `${prefix}${timeWithSingularOrPluralNoun(Math.round(elapsed/msPerDay), 'day')}${sufix}`;
    else if (elapsed < msPerYear) return `${prefix}${timeWithSingularOrPluralNoun(Math.round(elapsed/msPerMonth), 'month')}${sufix}`;
    else return `${prefix}${timeWithSingularOrPluralNoun(Math.round(elapsed/msPerYear), 'year')}${sufix}`;
}

function timeWithSingularOrPluralNoun(time:any, noun:any){
    return `${time} ${time === 1? noun : noun + 's'}`;
}

export function numberCompactFormat(num:any, prefix:any= '') {
    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
    return prefix + formatter.format(num);
}

export async function oauthGoogleHandler() {
    try {
        await httpGetRequest(
            `/_a/oauth/authorize?redirectUrl=${FE_URL}/login&source=google`,
            {},
            (resp) => {
                let data = JSON.parse(resp.response);
                if (resp.status === 200) {
                    window.location.href = data.payload.redirect_url;
                } else {
                    showServerErrorMessage(
                        data.payload && data.payload.what ? data.what : DEFAULT_ERROR_MESSAGE
                    )
                }
            }
        )
        
    } catch (err:any) {
        showServerErrorMessage(err.message || DEFAULT_ERROR_MESSAGE);
    }
}

// add the events to sort a given list
// you need to previously add icons to the table with the data-sort attribute whose value is the name of the attribute you want to filter
export function setupDatatable(table:HTMLElement | null, list:any[], callback: (orderedList:any[]) => void){
    let orderDir = -1;
    let sortIcons = table?.querySelectorAll("[data-sort]");
    sortIcons?.forEach(item => item.addEventListener("click", function(e:any){
        let icon = e.target;
        let sortBy = icon.getAttribute("data-sort");
        if (icon.classList.contains("active")) {
            orderDir*= -1;
            icon.style.transform = orderDir == 1? "rotate(180deg)" : "rotate(0deg)";
        } else {
            sortIcons?.forEach(item => { 
                item.classList.remove("active");
                item.removeAttribute("style")
            });
            e.target.classList.add("active");
            orderDir = -1;
        }
        list.sort((a,b) => ((a[sortBy] > b[sortBy]) ? 1 : ((b[sortBy] > a[sortBy]) ? -1 : ((a[sortBy] && b[sortBy]) ? 0 : (a[sortBy] ? 1 : -1)))) * orderDir);
        callback(list);
    }))
}

export function clearForm(form:any){
    document.querySelector(form)?.reset();
}

export function isOwnerOrAdmin(userData:any) {
    return userData?.member_role === ADMIN || userData?.member_role === OWNER;
}

export function isSiteAdmin(userData:any) {
    return userData?.tenant_role === TENANT_ADMIN;
}

export function showInfoMessage(message:any) {
    message = '<i class="fas fa-info-circle fa-lg me-2"></i>' + message;
    showToastMessage(message, TOAST_TYPE.INFO);
}
export function showSuccessMessage(message:any) {
    message = '<i class="fas fa-check fa-lg me-2"></i>' + message;
    showToastMessage(message, TOAST_TYPE.SUCCESS);
}
export function showWarningMessage(message:any) {
    message = '<i class="fas fa-exclamation-triangle fa-lg me-2"></i>' + message;
    showToastMessage(message, TOAST_TYPE.WARNING);
}
export function showDangerMessage(message:any) {
    message = '<i class="fas fa-exclamation-circle fa-lg me-2"></i>' + message;
    showToastMessage(message, TOAST_TYPE.DANGER);
}
export function showServerErrorMessage(resp:ResponseClass) {
    let message = JSON.parse(resp.response)?.payload?.what;
    let type:string = TOAST_TYPE.DANGER;
    switch(resp.status) {
        case 200:
            type: TOAST_TYPE.SUCCESS;
            message = "Successful operation";
        case 400:
            type = TOAST_TYPE.WARNING;
            message = message || "Bad Request";
            break;
        case 401:
            type = TOAST_TYPE.WARNING;
            message = message || "Unauthorized";
            removeTokenFromLocalStorage();
            break;
        case 403:
            type = TOAST_TYPE.WARNING;
            message = message || "Forbidden";
            removeTokenFromLocalStorage();
            break;
        case 404:
            type = TOAST_TYPE.WARNING;
            message = message || "Resource not found";
            break;
        case 500:
            type = TOAST_TYPE.DANGER;
            message = message || "Oops! something went wrong, please try again later";
            break;
        default: 
            type = TOAST_TYPE.DANGER;
            message = message || "Oops! something went wrong, please try again later";
    }
    showToastMessage(message, type);
}

// type: info, success, danger, warning 
function showToastMessage(message:any, type:any) {
    let wrapper = document.getElementById("toasts-wrapper");
    let toast = document.createElement("div");
    toast.innerHTML += `
    <div class="toast toast-${type} toast-fixed fade show" role="alert" aria-live="assertive" aria-atomic="true" data-mdb-autohide="true" data-mdb-delay="1000" data-mdb-position="top-right" data-mdb-offset="50" data-mdb-append-to-body="true" data-mdb-stacking="false" data-mdb-color="${type}">
        <div class="toast-header">
            <span class="me-auto">${message}</span>
            <button type="button" class="btn-close" data-mdb-dismiss="toast" aria-label="Cerrar"></button>
        </div>
    </div>
    `;
    wrapper?.appendChild(toast);
    setTimeout(function(){
        toast.querySelector(".toast")?.classList.remove("show");
        toast.remove();
    }, 3000);
}